import React,{useState, useEffect} from 'react'
import Axios from 'axios';
import {ROUTE_API} from '../../Constants/index';
import ModalError from '../ModalError/index';
import swal from 'sweetalert';
import './style.css';

const DashTable = () => {


const [users, setUsers] = useState()

useEffect(() => {
    Axios.get(`${ROUTE_API}/getAllUsers`).then((response) => {
        setUsers(response.data)
    })

}, []);


const deleteUser = (id) => {
    swal({
        title: "Está seguro?",
        text: "Una vez borrado, no será capaz de recuperar este usuario!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
        if (willDelete) {
    Axios.delete(`${ROUTE_API}/deleteUser/${id}`).then((response) => {
        setUsers(users?.filter((value) => {
            return value.idusers !== id;
    }))
        console.log('Response',response)
    })
        
        swal("Tu usuario fue borrado!", {
            icon: "success",
        });
        } else {
            swal("Tu usuario no fue borrado");
        } 
    });
}

if(users?.length > 1){
return (
<div className="tableContainer">
    <table className="content-table">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th>Borrar Usuario</th>
            </tr>
        </thead>
        <tbody>
            {users?.filter(users => users.idusers !== 2 ).map((user,index) => {
                return (
                    <>
                        <tr key={index}>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td><button id="deleteInfo" className="operationButton" onClick={() => deleteUser(user.idusers)}>Borrar</button></td>
                        </tr>
                    </>
                )})}
        </tbody>
    </table>
</div>
    )
                }else{
                    return <ModalError text={'USUARIO NO ENCONTRADO'}/>
                }
}

export default DashTable
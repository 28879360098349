import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from "react-icons/bs";
import * as FcIcons from "react-icons/fc";
import * as FiIcons from "react-icons/fi";
import * as CgIcons from "react-icons/cg"
import swal from 'sweetalert';
import moment from 'moment';

//SIZE
export const IconSize = 25;
export const valueWidth = {width:'100%'};

//API ROUTE 
export const ROUTE_API = 'http://34.95.244.14:4000';


//STRINGS
export const defaultTitleBarTable = 'Income / Expense bars table';
export const defaultTitleCakeTable = 'Income / Expense pie table';
export const formRegisterTitle = 'Transaction registration form';
export const deleteButtonText = "Delete";
export const editButtonText = "Edit";
export const defaultHorizontalTable = 'Income / Expense Horizontal table'
export const defaultLabel = 'Percentages of operations';
export const Ingress = 'Ingress';
export const Egress = 'Egress';
export const FilterButtonText = 'Filter Values'
export const IngressIconColor = 'green';
export const EgressIconColor = 'red';
export const showHalf = 'Show half of the list';
export const hideHalf = 'Collapse list in half';
export const types = ["Ingress", "Egress"];
export const options = ["AMOUNT","DATE","CONCEPT"]
export const defaultBackgroundColorEgress = 'rgba(247, 120, 112)';
export const defaultBackgroundColorIngress = 'rgba(53, 238, 198)';
export const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const monthsFilter = [
    {id:'01', value:'Enero'},
    {id:'02', value:'Febrero'}, 
    {id:'03', value:'Marzo'}, 
    {id:'04', value:'Abril'},
    {id:'05', value:'Mayo'}, 
    {id:'06', value:'Junio'}, 
    {id:'07', value:'Julio'},
    {id:'08', value:'Agosto'},
    {id:'09', value:'Septiembre'}, 
    {id:'10', value:'Octubre'}, 
    {id:'11', value:'Noviembre'}, 
    {id:'12', value:'Diciembre'},
    ];
export const columnInfo = [
    {title:'Date'},
    {title:'Type'},
    {title:'Concept'},
    {title:'Amount'},
]


//NUMBERS
export const borderWidth = 1
export const amountIngress = [0,0,0,0,0,0,0,0,0,0,0,0]
export const amountEgress = [0,0,0,0,0,0,0,0,0,0,0,0]
export const filterAmountTypes = [
    "0 - 1000",
    "1000 - 5000",
    "5000 - 10000",
    "10000 - 50000",
    "50000 - 100000"
]

//FUNCTIONS
export const saveToken = (value) => {
    localStorage.setItem('token',value)
    window.location.reload(); 
} 
export const saveIdUser = (value) => {
    localStorage.setItem('idUser',value)
} 

export const logOut = () => {swal({
    title: "Esta seguro que quiere cerrar sesión?",
    text: "Será redirigido a la página de acceso.",
    icon: "warning",
    buttons: true,
    dangerMode: true,
}).then((out) => {
if (out) {
    localStorage.removeItem('token')
    localStorage.removeItem('idUser')
        swal("Adiós, vuelva pronto", {
            icon: "success",
        }).then((res) => {
            window.location.reload(); 
            })
        }   
    })
}

export const onShowInfo = value => {
    const found = infoModal.find(element => {

    return element.type === value
});
swal({
    text: found.text, 
    button: 'Continuar',
    icon:"info"
})
}

export const infoFunction = type => onShowInfo(type)
export const parseNum = value => parseInt(value.split('-').slice(1).slice(0,1))
export const sumArray = (arr) => arr.reduce((sum, value) => ( sum + value ), 0);
export const totalEgressIngress = (arr, key) => arr?.filter(item => item.types === key).map(item => item.amount).reduce((sum, value) => ( sum + value ), 0)

export const sumAmountsByAmount = arr => { 
    const group = arr.reduce((p,c)=>{ 
        p[c.id] = (p[c.id]  || 0)+c.amount;
        return p;
    },{})
    
    const result = Object.keys(group).map(e=>{ 
        const o = {};
        o.id = e;
        o.amount = group[e];
        return o;
    })
    
    return result                                    
    }




export const formatDate = (date) => moment(date).format('YYYY-MM-DD')
export const calculateCurrentMoney = (arr) => arr.reduce((accum, currentValue) => accum - currentValue) 
export const replaceElement = (arr, value) => arr.map((dato) => {
    if(dato.id === value.id){
        dato = value
    }
        return dato;
    });

export const filterDate = (arr, dateParam, amountParam) => {
        let dataIndex
        let amountParams = amountParam.split('-').map(item => parseInt(item))
            dataIndex = monthsFilter.find(item => item.value === dateParam)
            const filterValue = arr.filter(item => moment(item.date).format('MM') === dataIndex.id).filter(item => ((item.amount >= amountParams[0])&&(item.amount <= amountParams[1])))
        return filterValue
    }




// OBJECTS
export const infoModal = [
    {type:"amount", text:"Ingrese el tipo de monto que quiere registrar."},
    {type:"concept", text:"Ingrese el concepto del registro."},
    {type:"date", text:"Ingrese fecha del registro."},
    {type:"type", text:"Seleccióne el tipo de registro."}
]
export const fieldInfo = [
    {type:"text", 
    inputType:"input",
    name:"concept", 
    id:"concept", 
    placeHolder:"Ingrese concepto", 
    htmlFor:"concept", 
    labelText:"Concepto", 
    icon:<FcIcons.FcSurvey 
    size={IconSize} 
    onClick={()=>infoFunction("concept")} />,
    registerInfo:{ required: {
        value: true,
        message:'Un concepto es requerido'
    },
    maxLength:{
        value: 20,
        message:'Máximo 20 caracteres'
    },
    minLength: {
        value: 5,
        message: 'Mínimo 5 caracteres'
    },
    validate: (value) => {
        return [
            /^[A-Za-z\s]+$/ 
        ].every((pattern) => pattern.test(value)) || "Only letters"
        }
    }
},
    

    {type:"number", 
    inputType:"input", 
    name:"amount", 
    id:"amount", 
    placeHolder:"Ingrese monto", 
    htmlFor:"amount", 
    labelText:"Monto", 
    icon:<FcIcons.FcMoneyTransfer
    size={IconSize} 
    onClick={()=>infoFunction("amount")} 
    />,
    registerInfo:{ required: {
        value: true,
        message:'Un monto es requerido'
    },
    maxLength:{
        value: 5, 
        message:'Máximo 5 caracteres'
    },
    minLength: {
        value: 2,
        message: 'Mínimo 2 caracteres'
    },
    validate: (value) => {
        return [
            /^[1-9]\d*$/
        ].every((pattern) => pattern.test(value)) || "Only Numbers"
    }
}
},


    {type:"date",
    inputType:"input",
    name:"date", 
    id:"date",
    placeHolder:"Ingrese fecha", 
    htmlFor:"date", 
    labelText:"Fecha", 
    icon:<FcIcons.FcOvertime 
    size={IconSize} 
    onClick={()=>infoFunction("date")}
    />,
    registerInfo:{ required: {
        value: true,
        message:'Una fecha es requerida'
    },
}
},

    {name:"type", 
    inputType:"select", 
    placeHolder:"Ingrese fecha", 
    htmlFor:"date", 
    labelText:"Tipo", 
    optionText: types,
    icon:<FcIcons.FcRules size={IconSize} 
    onClick={()=>infoFunction("type")} 
    
    />},
]

export const fieldFilterInfo = [
    {name:"amount", 
    inputType:"select", 
    placeHolder:"Seleccionar monto", 
    htmlFor:"amount", 
    labelText:"Filtrar por Monto",
    optionText: filterAmountTypes,
    },
    {
        name:"date", 
        inputType:"select", 
        placeHolder:"Seleccionar mes", 
        htmlFor:"date", 
        labelText:"Filtrar por Mes",
        optionText: months,
    }
]



// NAV INFO
export const SidebarData = [
    {
        title: 'Página Principal',
        path: '/',
        icon: <AiIcons.AiFillHome />,
        cName: 'nav-text',
},
    {
        title: 'Estadísticas',
        path: '/statistics',
        icon: <BsIcons.BsGraphUp />,
        cName: 'nav-text',
    },
    {
        title: 'Regristrar Acción',
        path: '/register',
        icon: <BsIcons.BsPencil/>,
        cName: 'nav-text',
    },
    {
        title: 'Registros Generales',
        path: '/records',
        icon: <FiIcons.FiDatabase/>,
        cName: 'nav-text',
    },
    {
        title: 'Panel de Usuarios',
        path: '/dashBoard',
        icon: <CgIcons.CgMenuBoxed/>,
        cName: 'nav-text',
    },
    {
        title: 'Cerrar Sesión',
        path: '/SignIn',
        icon: <FiIcons.FiUserX/>,
        cName: 'nav-text',
        func: logOut
    },
];

export const fieldsEditForm = [
    {type:"text", 
    inputType:"input",
    name:"concept", 
    id:"concept", 
    placeHolder:"Ingrese concepto", 
    htmlFor:"concept", 
    registerInfo:{ required: {
        value: true,
        message:'Un concepto es requerido'
    },
    maxLength:{
        value: 20,
        message:'Máximo 20 caracteres'
    },
    minLength: {
        value: 5,
        message: 'Mínimo 5 caracteres'
    },
    validate: (value) => {
        return [
            /^[A-Za-z\s]+$/ 
        ].every((pattern) => pattern.test(value)) || "Only letters"
        }
    }
},
    

    {type:"number", 
    inputType:"input", 
    name:"amount", 
    id:"amount", 
    placeHolder:"Ingrese monto", 
    htmlFor:"amount", 
    registerInfo:{ required: {
        value: true,
        message:'Un monto es requerido'
    },
    maxLength:{
        value: 5, 
        message:'Máximo 5 caracteres'
    },
    minLength: {
        value: 2,
        message: 'Mínimo 2 caracteres'
    },
    validate: (value) => {
        return [
            /^[1-9]\d*$/
        ].every((pattern) => pattern.test(value)) || "Only Numbers"
        }
    }
},

    {type:"date",
    inputType:"input",
    name:"date", 
    id:"date",
    placeHolder:"Ingrese fecha", 
    htmlFor:"date",  
    registerInfo:{ required: {
        value: true,
        message:'Una fecha es requerida'
            },
        }
    },
]

export const fieldsUserInfo = [
    {type:"text", 
    inputType:"input",
    name:"username", 
    id:"username", 
    placeHolder:"Ingrese usuario", 
    htmlFor:"username", 
    labelText:"Username", 
    registerInfo:{ required: {
        value: true,
        message:'Un usuario es requerido'
    },
    maxLength:{
        value: 20,
        message:'Máximo 20 caracteres'
    },
    minLength: {
        value: 5,
        message: 'Mínimo 5 caracteres'
    },
    validate: (value) => {
        return [
            /^[A-Za-z\s]+$/ 
        ].every((pattern) => pattern.test(value)) || "Only letters"
        }
    }
},

    {
    type:"email", 
    inputType:"input", 
    labelText:"E-mail", 
    name:"email", 
    id:"email", 
    placeHolder:"Ingrese email", 
    htmlFor:"email",
    registerInfo:{ required: {
        value: true,
        message: "ingrese un email válido",
    },
    validate: (value) => {
        return [
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        ].every((pattern) => pattern.test(value)) || "E-mail format wrong"
    }
}
},
    {
    type:"password", 
    inputType:"input",
    labelText:"Password", 
    name:"password", 
    id:"password", 
    placeHolder:"Ingrese contraseña", 
    htmlFor:"password", 
    registerInfo:{ required: {
        value: true,
        message: "una contraseña es requerida",
        },
    },
}
]

import React from 'react';
import TableRecords from '../TableRecords/index';
import HomeTable from '../HomeTable/index';
import {columnInfo} from '../../Constants/index';

const Balance = ({listRecords,numValue, currentValue}) => {

    return (
    <div className="balanceContainer">
    
    <HomeTable
    titleColumn={['Ingresos', 'Egresos']}
    numValue={numValue}
    title={'Registro de Balances'}
    />

    <HomeTable
    numValue={currentValue}
    titleColumn={['Cantidad Total']}
    title={'Balance Actual'}
    />
    <TableRecords 
    listRecords={listRecords}
    textColumn={columnInfo}
    title={'Lista de los Primeros 10 Registros'}
    />
    
    
    </div>
    )}
export default Balance

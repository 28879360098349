import React from 'react';
import './style.css';

const Footer = () => {
return (
    <div className="mainFooterContainer">
        <div className="footer">
            <h3>© Copyright UADE 2023</h3>
        </div>
    </div>
)
}

export default Footer